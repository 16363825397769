import { Flex } from "@redotech/redo-web/flex";
import { Text, TextProps } from "@redotech/redo-web/text";
/**
 * Display HTML if it is valid HTML, otherwise display the text
 * @param html - The HTML to display
 * @param textProps - The props to pass to the Text component
 * @returns A Box with the HTML or text
 */
export function MaybeDisplayHtml({
  html,
  textProps,
}: {
  html: string;
  textProps?: TextProps;
}) {
  const isHtml = html.includes("<") && html.includes(">");

  if (!isHtml) {
    return <Text {...textProps}>{html}</Text>;
  }
  return (
    <Flex gap="md" grow={1} justify="flex-start">
      <div
        // TODO parse HTML safely
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Flex>
  );
}
