import { composeWrappers } from "@redotech/react-util/component";
import {
  TextSizeValue,
  TextWeightValue,
} from "@redotech/redo-web/theme/typography";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  JSXElementConstructor,
  memo,
  MouseEvent,
  useState,
} from "react";
import CircleSpinner from "../../circle-spinner.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { OverflowTooltip } from "../../tooltip/overflow-tooltip";
import * as redoButtonThemeCss from "./redo-button-themes.module.css";
import * as redoButtonCss from "./redo-button.module.css";

export const redoButtonSize = ["xs", "sm", "md", "lg", "xl"] as const;
export type RedoButtonSize = (typeof redoButtonSize)[number];

export const redoButtonTheme = [
  "normal",
  "destructive",
  "success",
  "action",
] as const;
export type RedoButtonTheme = (typeof redoButtonTheme)[number];

export const redoButtonHierarchy = [
  "primary",
  "secondary",
  "tertiary",
  "link",
  "link_underline",
] as const;
export type RedoButtonHierarchy = (typeof redoButtonHierarchy)[number];

/**
 * @param pending -- custom pending states can be achieved by passing spinner icon leading, new text, and disabled
 *
 * @param dangerousStyleThatShouldOnlyBeUsedInSpecificSituations -- this should only defined if we need the button to follow a merchant's branding.
 * Do not use in other situations.
 *
 * @param type -- if you are using an HTML form, you may care about setting this.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#type}
 *
 */
export interface RedoButtonProps {
  onClick?(event: MouseEvent<HTMLButtonElement>): void;
  dataTestId?: string;
  size?: RedoButtonSize;
  theme?: RedoButtonTheme;
  hierarchy?: RedoButtonHierarchy;
  text?: string;
  textWeight?: TextWeightValue;
  IconLeading?: JSXElementConstructor<any> | "placeholder";
  IconTrailing?: JSXElementConstructor<any> | "placeholder";
  dontRestrainIconWidth?: boolean;
  disabled?: boolean;
  pending?: boolean;
  pressed?: boolean;
  buttonClassName?: string;
  buttonContentClassName?: string;
  buttonId?: string;
  buttonValue?: string;
  centerItems?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  form?: string;
  flexGrow?: number;
  useOverflowTooltip?: boolean;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
}

/**
 * Figma
 * https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=3287-427074&t=p984S770InFMNsms-0
 */
export const RedoButton = memo(
  forwardRef(function RedoButton(
    {
      dataTestId,
      size = "sm",
      theme = "normal",
      hierarchy = "tertiary",
      IconLeading,
      IconTrailing,
      text,
      textWeight = "medium",
      onClick,
      disabled,
      pending,
      centerItems = true,
      buttonClassName,
      buttonContentClassName,
      buttonId,
      buttonValue,
      dontRestrainIconWidth,
      className,
      type = "button",
      form,
      pressed,
      flexGrow,
      useOverflowTooltip = false,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
    }: RedoButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const isIconOnly = !text;
    const [textRef, setTextRef] = useState<HTMLElement | null>(null);

    function wrappedIcon(
      Icon: JSXElementConstructor<any> | "placeholder",
      extraClasses?: string,
    ) {
      const icon = typeof Icon === "string" ? <div /> : <Icon />;

      return (
        <Flex
          align="center"
          className={classNames(
            redoButtonCss.iconContainer,
            redoButtonCss[size],
            dontRestrainIconWidth && redoButtonCss.dontRestrainIconWidth,
            extraClasses,
          )}
          justify="center"
        >
          {icon}
        </Flex>
      );
    }

    const fontSize = sizeToFontSize[size];

    const px = evaluatePx(size, isIconOnly, hierarchy);

    return (
      <div
        className={className}
        style={flexGrow !== undefined ? { flexGrow } : undefined}
      >
        {composeWrappers((inner) =>
          useOverflowTooltip ? (
            <OverflowTooltip
              overflowRef={textRef}
              tooltipProps={{ title: text }}
              wrapperStyle={{ display: "inline-block", width: "100%" }}
            >
              {inner}
            </OverflowTooltip>
          ) : (
            inner
          ),
        )(
          <button
            className={classNames(
              redoButtonCss.button,
              disabled || pending ? redoButtonThemeCss.disabled : undefined,
              redoButtonCss[size],
              themeClass[theme],
              hierarchyClass[hierarchy],
              pressed && redoButtonThemeCss.pressed,
              buttonClassName,
            )}
            data-testid={dataTestId}
            disabled={disabled || pending}
            form={form}
            id={buttonId}
            onClick={onClick}
            ref={ref}
            style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
            type={type}
            value={buttonValue}
          >
            {pending && wrappedIcon(CircleSpinner, redoButtonCss.spinner)}
            <Flex
              align="center"
              className={classNames(
                redoButtonCss.buttonContent,
                pending && redoButtonCss.pending,
                buttonContentClassName,
              )}
              gap={sizeToGap[size]}
              justify={centerItems ? "center" : "flex-start"}
              px={px}
            >
              {IconLeading && wrappedIcon(IconLeading)}
              {text && (
                <Text
                  fontSize={fontSize}
                  fontWeight={textWeight}
                  overflow="hidden"
                  px="xxs"
                  ref={setTextRef}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {text}
                </Text>
              )}
              {IconTrailing && wrappedIcon(IconTrailing)}
            </Flex>
          </button>,
        )}
      </div>
    );
  }),
);

function evaluatePx(
  size: RedoButtonSize,
  isIconOnly: boolean,
  hierarchy: RedoButtonHierarchy,
) {
  if (hierarchy === "link") {
    return "none";
  }
  if (isIconOnly) {
    return iconOnlySizeToPaddingX[size];
  }
  return sizeToPaddingX[size];
}

const themeClass: Record<RedoButtonTheme, string> = {
  ["normal"]: redoButtonThemeCss.themeNormal,
  ["destructive"]: redoButtonThemeCss.themeDestructive,
  ["success"]: redoButtonThemeCss.themeSuccess,
  ["action"]: redoButtonThemeCss.themeAction,
};

const hierarchyClass: Record<RedoButtonHierarchy, string> = {
  ["primary"]: redoButtonThemeCss.hierarchyPrimary,
  ["secondary"]: redoButtonThemeCss.hierarchySecondary,
  ["tertiary"]: redoButtonThemeCss.hierarchyTertiary,
  ["link"]: redoButtonThemeCss.hierarchyLinkGray,
  ["link_underline"]: redoButtonThemeCss.hierarchyLinkUnderline,
};

const sizeToPaddingX: Record<RedoButtonSize, SpacingValue> = {
  ["xs"]: "md",
  ["sm"]: "lg",
  ["md"]: "lg",
  ["lg"]: "lg",
  ["xl"]: "xl",
};

const iconOnlySizeToPaddingX: Record<RedoButtonSize, SpacingValue> = {
  ["xs"]: "sm",
  ["sm"]: "sm",
  ["md"]: "md",
  ["lg"]: "md",
  ["xl"]: "lg",
};

const sizeToFontSize: Record<RedoButtonSize, TextSizeValue> = {
  ["xs"]: "xs",
  ["sm"]: "xs",
  ["md"]: "sm",
  ["lg"]: "sm",
  ["xl"]: "md",
};

const sizeToGap: Record<RedoButtonSize, TextSizeValue> = {
  ["xs"]: "xs",
  ["sm"]: "xs",
  ["md"]: "xs",
  ["lg"]: "xs",
  ["xl"]: "sm",
};
