import { AuthGuard } from "@redotech/redo-customer-portal-app/contexts/auth";
import { Outlet, RouteObject } from "react-router-dom";
import { Page } from "../order/page";

export const registerRoutes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <Page alwaysShowNavbar>
          <Outlet />
        </Page>
      </AuthGuard>
    ),
    children: [
      {
        path: "retail",
        children: [
          {
            index: true,
            lazy: async () => {
              const { Main: RegistrationPortal } = await import(
                "@redotech/redo-customer-portal-app/register/main"
              );
              return { element: <RegistrationPortal /> };
            },
          },
          {
            path: ":id",
            lazy: async () => {
              const { RegisteredWarrantyDetails } = await import(
                "../warranties/registered-warranty-details"
              );
              return { element: <RegisteredWarrantyDetails /> };
            },
          },
        ],
      },
    ],
  },
];
