import { GetReturnErrorCode } from "@redotech/customer-portal-sdk/rpc/schema/get-return";
import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { useReturnConfirmation } from "@redotech/redo-customer-portal-shared/hooks/useReturnConfirmation/use-return-confirmation";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { SettingsContext } from "../../contexts/settings";
import { ItemDetail } from "../shared-components/item-detail";
import { Addresses } from "../submission/components/addresses";
import { Card } from "../submission/components/card";
import { DeadlineCard } from "./components/deadline-card";
import { ManualLabelCard } from "./components/manual-label-card";
import { MaybeDisplayHtml } from "./components/maybe-display-html";
import { ReturnItems } from "./components/return-items";
import { ReturnStepsCard } from "./components/return-steps-card";
import { SummaryCardConfirmation } from "./components/summary-card-confirmation";
import { TopCard } from "./components/top-card";
import { WarningsCard } from "./components/warnings-card";
import * as detailCss from "./confirmation.module.css";
import { Spinner } from "./spinner";
export function ReturnConfirmation() {
  const returnId = useParams().returnId || "";

  const settings = useContext(SettingsContext);
  const {
    loadingReturn,
    error,
    errorCodeData,
    returnHeader,
    steps,
    stepResource,
    rejected,
    rejectHtml,
    customSuccessMessage,
    warnings,
    deadlineDays,
    showDeadline,
    products,
    productsZod,
    currency,
    newItems,
    newItemsHeader,
    showAddresses,
    newOrderAddress,
    returnAddress,
    returnTotals,
    summaryFeesHeader,
    summaryFeesOrderHeader,
    texts,
    labelDeducted,
    showCreationStatus,
    shouldShowSummary,
    manualLabelInfo,
    showReturnSteps,
  } = useReturnConfirmation({
    settings,
    returnId,
    navigateToCheckout: (url: string) => {
      window.open(url, "_blank");
    },
  });

  const screen = useScreenType();

  const shouldShowDesktopView = screen === ScreenType.DESKTOP;

  const SummaryCard = useMemo(
    () =>
      returnTotals ? (
        <Flex flexDirection="column" pb="4xl">
          <SummaryCardConfirmation
            currency={currency}
            feesHeader={summaryFeesHeader}
            feesOrderHeader={summaryFeesOrderHeader}
            labelDeducted={labelDeducted ?? false}
            products={productsZod}
            settings={settings}
            totals={returnTotals}
          />
        </Flex>
      ) : null,
    [
      labelDeducted,
      returnTotals,
      currency,
      summaryFeesHeader,
      summaryFeesOrderHeader,
      productsZod,
      settings,
    ],
  );

  const AddressesComponent = useMemo(() => {
    const addresses = [];
    if (newOrderAddress) {
      addresses.push(newOrderAddress);
    }
    if (returnAddress) {
      addresses.push(returnAddress);
    }
    return showAddresses ? (
      <Flex flexDirection="column" gap="lg" pt="md">
        <Text fontWeight="bold">Shipping information</Text>
        <Addresses addresses={addresses} />
      </Flex>
    ) : null;
  }, [showAddresses, newOrderAddress, returnAddress]);
  //Error code handling
  if (errorCodeData) {
    switch (errorCodeData.type) {
      case GetReturnErrorCode.ReturnNotFound:
        return (
          <Flex flexDirection="column" gap="sm" p="md">
            <Text fontWeight="bold" textAlign="center">
              {errorCodeData?.data.texts.title}
            </Text>
          </Flex>
        );
      default:
        assertNever(errorCodeData.type);
    }
  }
  if (error) {
    return <Text>{error}</Text>;
  }
  if (loadingReturn) {
    return (
      <Card fullscreen>
        <Flex
          align="center"
          flexDirection="column"
          gap="md"
          grow={1}
          justify="center"
        >
          <Spinner />
          <div style={{ height: "50px" }} />
        </Flex>
      </Card>
    );
  }

  if (rejected) {
    return (
      <Flex flexDirection="column" gap="sm" p="md">
        <Text fontWeight="bold" textAlign="center">
          {texts.rejectedText}
        </Text>
        <MaybeDisplayHtml html={rejectHtml} />
      </Flex>
    );
  }

  //Animation for return creation status
  if (showCreationStatus) {
    const timeBetweenAnimations = 4000;

    return (
      <Card fullscreen>
        <Flex
          align="center"
          flexDirection="column"
          gap="md"
          grow={1}
          justify="center"
        >
          <Spinner />
          <TypeAnimation
            className={detailCss.typeAnimationText}
            cursor={false}
            omitDeletionAnimation
            sequence={[
              "Creating return...",
              timeBetweenAnimations,
              "Generating return label...",
              timeBetweenAnimations,
              "Updating order...",
              timeBetweenAnimations,
              "Finishing up...",
            ]}
            speed={80}
            style={{ height: "50px" }}
            wrapper="span"
          />
        </Flex>
      </Card>
    );
  }

  return (
    <Card fullscreen>
      <Flex flexDirection="column" gap="2xl" grow={1} pb="4xl">
        <Flex
          className={shouldShowDesktopView ? detailCss.outlinedCard : undefined}
          flexDirection="column"
          gap="2xl"
          pb={shouldShowDesktopView ? "3xl" : "none"}
          pt="3xl"
          px={shouldShowDesktopView ? "3xl" : "none"}
        >
          <TopCard
            action={returnHeader.onClick}
            actionTitle={returnHeader.onClickTitle}
            status={returnHeader.status}
            subtitle={returnHeader.subtitle}
            title={returnHeader.title}
          />

          {customSuccessMessage && (
            <MaybeDisplayHtml html={customSuccessMessage} />
          )}

          <Flex flexDirection="column" gap="lg">
            <Divider />
            {warnings && warnings.length > 0 && (
              <WarningsCard warnings={warnings} />
            )}
            {manualLabelInfo.showManualLabel && (
              <ManualLabelCard instructions={manualLabelInfo.instructions} />
            )}
            {showReturnSteps && (
              <ReturnStepsCard
                copyResource={stepResource}
                returnId={returnId}
                steps={steps}
              />
            )}
            {showDeadline && <DeadlineCard deadlineDays={deadlineDays} />}
            {!shouldShowDesktopView && <Divider />}
          </Flex>
        </Flex>
        {shouldShowSummary && (
          <Flex
            flexDirection="column"
            gap="2xl"
            px={shouldShowDesktopView ? "3xl" : "none"}
          >
            {shouldShowDesktopView && SummaryCard}
            <Flex flexDirection="column" gap="3xl">
              <ReturnItems currency={currency} products={products} />
              <Divider />
              {newItems && newItems?.length > 0 && (
                <Flex flexDirection="column" gap="lg">
                  <Flex flexDirection="column" gap="xl">
                    <Text fontSize="lg" fontWeight="semibold">
                      {newItemsHeader}
                    </Text>
                    {newItems?.map((item, i) => (
                      <ItemDetail item={item} key={item.title + i} />
                    ))}
                  </Flex>
                  <Divider />
                </Flex>
              )}
            </Flex>
            {AddressesComponent}
            {!shouldShowDesktopView && SummaryCard}
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
