import { Currency } from "@redotech/money/currencies";
import { useConfirmationSummary } from "@redotech/redo-customer-portal-shared/hooks/useReturnConfirmation/use-confirmation-summary";
import { ReturnAppSettings } from "@redotech/redo-customer-portal-shared/settings";
import { ReturnZod } from "@redotech/redo-model/return";
import { ReturnTotals } from "@redotech/redo-model/return-totals-calculator";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { captializeWords } from "@redotech/string/util";
import { SummaryCard } from "../../submission/components/summary-card";
export function SummaryCardConfirmation({
  totals,
  currency,
  feesHeader,
  feesOrderHeader,
  products,
  labelDeducted,
  settings,
}: {
  feesHeader: string;
  feesOrderHeader: string;
  totals: ReturnTotals;
  currency: Currency;
  products: ReturnZod["products"] | undefined;
  labelDeducted: boolean;
  settings: ReturnAppSettings | undefined;
}) {
  const { isLoading, productSubtotals } = useConfirmationSummary({
    totals,
    products,
  });

  if (isLoading) {
    return <LoadingRedoAnimation />;
  }
  console.log("totals", totals);
  return (
    <SummaryCard
      adjustmentValue={totals.totalAdjustmentValue}
      adjustmentValueString={captializeWords(
        settings?.returnAdjustment[
          totals.totalAdjustmentValue > 0 ? "positiveName" : "negativeName"
        ] ?? "",
      )}
      currency={currency}
      feesHeader={feesHeader}
      feesOrderHeader={feesOrderHeader}
      labelDeducted={labelDeducted}
      newOrderItemsSubtotals={[]}
      newOrderTaxes={[
        { title: "Taxes", total: totals.advancedExchangeItemsTaxInNewOrder },
      ]}
      newOrderValue={totals.newOrderValue}
      newVariantExchangeItemSubtotals={[]}
      pickupFee={undefined}
      productSubtotals={productSubtotals}
      returnValue={totals.totalReturnValue}
      shippingFee={totals.shippingFee}
      submitted
      taxSubtotals={[{ title: "Taxes", total: totals.totalTaxes }]}
      totalPaymentOwed={totals.totalPaymentOwed}
      totalRefund={totals.totalRefundWithoutShipping}
      totalShippingFee={totals.shippingFee}
      totalStoreCredit={totals.totalStoreCredit}
    />
  );
}
