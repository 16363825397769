// import { PurchaseChannel } from "./draft-registration";
import { RegistrationRewardType } from "./extended-warranty";
import { CustomerPortalVersion } from "./team";

export enum PurchaseChannel {
  RETAIL = "retail",
  ONLINE = "online",
}

export type WarrantiesSettings = {
  enabled: boolean;
  labelCoverageEnabled: boolean;
  warrantyRegistrationCollection?: string;
  customersCanStartClaims: boolean;
  registrationEnabled: boolean;
  registrationRewardType?: RegistrationRewardType;
  portalFourRegistration: boolean;
  registrationRewardDiscountId?: string;
  repairFeeEnabled: boolean;
  defaultRepairMaxPrice: number;
  defaultRepairMinPrice: number;
  repairPricingRuleSet: {
    minPrice: number;
    maxPrice: number;
    collectionHandle: string;
    collectionTitle: string;
  }[];
  purchaseChannels: { displayName: string; purchaseChannel: PurchaseChannel }[];
};

const VALID_CUSTOMER_PORTAL_VERSIONS = [
  CustomerPortalVersion.V3_5,
  CustomerPortalVersion.V4_0,
];

export function shouldShowWarrantyButton(
  customerPortalVersion?: CustomerPortalVersion,
  customersCanStartClaims?: boolean,
) {
  if (!customerPortalVersion || !customersCanStartClaims) {
    return false;
  }
  return (
    VALID_CUSTOMER_PORTAL_VERSIONS.includes(customerPortalVersion) &&
    customersCanStartClaims
  );
}
