import { z } from "zod";
import { zExt } from "./common/zod-util";
import {
  OnlineReferenceId,
  parseOnlineReferenceId,
} from "./draft-registration";
import { ReturnProductSchema } from "./draft-return/draft-return-items";
import { ExtendedWarrantySchema } from "./extended-warranty";
import { PurchaseChannel } from "./warranties";

const OnlineSourceSchema = z.object({
  purchaseChannel: z.literal(PurchaseChannel.ONLINE),
  orderId: zExt.objectId(),
  referenceId: z.string(),
});

const RetailSourceSchema = z.object({
  purchaseChannel: z.literal(PurchaseChannel.RETAIL),
});

export const WarrantyRegistrationSourceSchema = z.discriminatedUnion(
  "purchaseChannel",
  [OnlineSourceSchema, RetailSourceSchema],
);

export type WarrantyRegistrationSource = z.infer<
  typeof WarrantyRegistrationSourceSchema
>;

export const WarrantyRegistrationSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  email: z.string(),
  name: z.string(),
  product: ReturnProductSchema,
  responses: z.array(
    z.object({ type: z.string(), value: z.any(), message: z.string() }),
  ),
  customer: zExt.objectId(),
  returnId: zExt.objectId().nullish(),
  extendedWarranty: ExtendedWarrantySchema.nullish(),
  optInMarketing: z.boolean().nullish(),
  discountCode: z.string().nullish(),
  source: WarrantyRegistrationSourceSchema.nullish(),
  // PaymentIntent is excluded since it contains sensitive data
});

export type WarrantyRegistration = z.infer<typeof WarrantyRegistrationSchema>;

export function getTotalRegistrationsByLineItemId(
  registrations: WarrantyRegistration[],
): Record<string, number> {
  return registrations
    .map((registration) => {
      if (registration.source?.purchaseChannel === PurchaseChannel.ONLINE) {
        return registration.source.referenceId as OnlineReferenceId;
      }
      return null;
    })
    .filter((referenceId) => referenceId !== null)
    .reduce(
      (acc, referenceId) => {
        const { lineItemId } = parseOnlineReferenceId(referenceId);
        acc[lineItemId] = (acc[lineItemId] ?? 0) + 1;
        return acc;
      },
      {} as Record<string, number>,
    );
}
