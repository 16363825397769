import { countries } from "@redotech/locale/countries";
import { usaProvinces } from "@redotech/locale/provinces";
import { Big } from "big.js";
import { Provider } from "../order";
import { MultipleChoiceTextAnswer } from "../return";
import { getTreatment, ReturnFlow, Step } from "../return-flow";
import {
  Condition,
  Parameters as ConditionParams,
  Coverage,
} from "../return-flow/condition";
import { ReturnOptionMethod } from "../return-flow/return-option";
import { compareMaybeGids } from "../shopify/gid-utils";
import {
  CompensationMethod,
  Order as DraftReturnOrder,
  DraftReturn as DraftReturnZod,
  PriceRange,
  ShipmentMethodName,
} from "./draft-return";
import {
  PendingReturnItem,
  ReturnableItem,
  ReturnProduct,
  VariantExchangeItem,
} from "./draft-return-items";

export type CombinedReturnItem = {
  returnableItem: ReturnableItem;
  pendingReturnItem: PendingReturnItem;
};

export function getCombinedReturnItem(
  draftReturn: DraftReturnZod,
  pendingItemId: string,
): CombinedReturnItem {
  const pendingReturnItem = draftReturn.pendingReturnItems.find(
    (pendingItem) => pendingItem.id.toString() === pendingItemId.toString(),
  );
  if (!pendingReturnItem) {
    throw new Error(`Pending item with id ${pendingItemId} not found`);
  }
  const returnableItem = draftReturn.returnableItems.find(
    (item) => item.id === pendingReturnItem?.returnableItemId,
  );
  if (!returnableItem) {
    throw new Error(
      `Returnable item with id ${pendingReturnItem?.returnableItemId} not found`,
    );
  }
  return { returnableItem, pendingReturnItem };
}

export function getCombinedReturnItems(
  draftReturn: DraftReturnZod,
): CombinedReturnItem[] {
  return draftReturn.pendingReturnItems.map((pendingItem) =>
    getCombinedReturnItem(draftReturn, pendingItem.id),
  );
}

export const getPrimaryProduct = (
  returnItem: ReturnableItem,
): ReturnProduct => {
  if (returnItem.isExpanded) {
    const primaryProduct = returnItem.products.find(
      (product) =>
        product.id === returnItem.primaryProductId &&
        product.isExpandedPrimaryProduct,
    );
    if (primaryProduct) {
      return primaryProduct;
    }
  }

  const primaryProduct = returnItem.products.find(
    (product) => product.id === returnItem.primaryProductId,
  );
  if (!primaryProduct) {
    throw new Error("Primary product not found");
  }
  return primaryProduct;
};

export const getNonPrimaryProducts = (
  returnItem: ReturnableItem,
): ReturnProduct[] => {
  if (
    returnItem.isExpanded &&
    returnItem.products.some((product) => product.isExpandedPrimaryProduct)
  ) {
    return returnItem.products.filter(
      (product) => !product.isExpandedPrimaryProduct,
    );
  } else {
    return returnItem.products.filter(
      (product) => product.id !== returnItem.primaryProductId,
    );
  }
};

export function getMultipleChoiceAnswers(
  multipeChoiceAnswers: readonly { step: number; answer: number }[],
  flow: ReturnFlow,
  flowPath: readonly number[],
): MultipleChoiceTextAnswer[] {
  const answers: MultipleChoiceTextAnswer[] = [];
  for (let i = 0; i < flowPath.length; i++) {
    const step = flow.steps[flowPath[i]];
    const stepIndex = flowPath[i];

    if (
      step.type.toString() === Step.MULTIPLE_CHOICE.description ||
      step.type.description === Step.MULTIPLE_CHOICE.description
    ) {
      const choice = multipeChoiceAnswers.find(
        (answer) => answer.step === stepIndex,
      );

      if (choice) {
        const option = (step as Step.MultipleChoice).options[choice.answer];
        if (option) {
          answers.push({
            answer: option.name,
            questionText: (step as Step.MultipleChoice).message,
            step: stepIndex,
          });
        }
      }
    }
  }
  return answers;
}

export function getCompensationMethod(
  draftReturn: DraftReturnZod,
): CompensationMethod | undefined {
  return draftReturn.compensationMethods?.length === 1
    ? draftReturn.compensationMethods[0]
    : draftReturn.compensationMethods.find(
        (method) => method.type === draftReturn.selectedMethod,
      );
}

export function compensationMethodHasRepair(
  compensationMethod: CompensationMethod,
) {
  return Object.values(compensationMethod.itemValues).some(
    (item) => item.strategy === "repair",
  );
}

export function hasReturnItemsThatRequireShipping(
  draftReturn: DraftReturnZod,
): boolean {
  return Object.values(
    getCompensationMethod(draftReturn)?.shippingRequired ?? {},
  ).some((required) => required);
}

export function getShipmentMethod(
  draftReturn: DraftReturnZod,
): ShipmentMethodName {
  return draftReturn?.shipmentMethod?.method ?? ShipmentMethodName.SELF_SHIP;
}

export function getBaseRepairFee(repairFee: PriceRange): number {
  return repairFee[0];
}

export const getReturnableItemTotalReturnValue = (
  returnItem: ReturnableItem | null,
  pendingItem: PendingReturnItem | null,
): Big => {
  if (!returnItem) {
    return Big(0);
  }
  return (returnItem?.products || []).reduce((acc, product) => {
    if (!returnItem?.isExpanded || product?.isExpandedPrimaryProduct) {
      return acc.plus(product.returnValue).times(pendingItem?.quantity ?? 1);
    }
    return acc;
  }, Big(0));
};

export const getReturnableItemTotalTax = (
  returnItem: ReturnableItem | null,
  pendingItem: PendingReturnItem | null,
): Big => {
  return (returnItem?.products || []).reduce((acc, product) => {
    if (!returnItem?.isExpanded || product.isExpandedPrimaryProduct) {
      return acc.plus(product.tax).times(pendingItem?.quantity ?? 1);
    }
    return acc;
  }, Big(0));
};

export const getReturnableItemTotalPrice = (
  returnItem: ReturnableItem | null,
  pendingItem: PendingReturnItem | null,
): Big => {
  return (returnItem?.products || []).reduce((acc, product) => {
    if (!returnItem?.isExpanded || product?.isExpandedPrimaryProduct) {
      return acc
        .plus(product?.originalPrice ?? product.price)
        .times(pendingItem?.quantity ?? 1);
    }
    return acc;
  }, Big(0));
};

export const getReturnableItemTotalGrams = (
  returnItem: ReturnableItem | null,
  pendingItem: PendingReturnItem | null,
): Big => {
  return (returnItem?.products || []).reduce(
    (acc, item) => acc.plus(item.grams).times(pendingItem?.quantity ?? 1),
    Big(0),
  );
};

export const isVariantExchange = (pendingItem: PendingReturnItem) =>
  (!!pendingItem.variantExchangeItem &&
    pendingItem.variantExchangeItem.priceDifference !== "apply") ||
  (pendingItem?.expandedProductVariantExchanges || []).length > 0;

export function getTotalReturnableValue(items: CombinedReturnItem[]): Big {
  return items.reduce(
    (acc, { pendingReturnItem, returnableItem }) =>
      // Variant exchanges don't count towards the returnable value,
      // since those dollars are already tied up 100% in the exchange.
      !isVariantExchange(pendingReturnItem)
        ? acc
            .plus(
              getReturnableItemTotalReturnValue(
                returnableItem,
                pendingReturnItem,
              ),
            )
            .plus(getReturnableItemTotalTax(returnableItem, pendingReturnItem))
        : acc,
    Big(0),
  );
}

export function isPrimaryProductCoveredByFinalSale(
  primaryItem: ReturnProduct,
  order: DraftReturnOrder | undefined,
): boolean {
  if (!order) {
    return false;
  }

  return (
    order.finalSaleReturnsProtected &&
    order.productsCoveredByFinalSale.includes(primaryItem.productId)
  );
}

export function getTestAndTreatment(draftReturn: DraftReturnZod): {
  testId: string | undefined;
  treatmentId: string | undefined;
} {
  for (const pendingItem of draftReturn.pendingReturnItems) {
    if (pendingItem?.flow) {
      const flow = pendingItem.flow as ReturnFlow;
      for (const pathIndex of pendingItem.flowPath) {
        if (
          (flow.steps[pathIndex].type as unknown as string) ===
          Step.AB_TEST.description
        ) {
          const testStep = flow.steps[pathIndex] as Step.ABTest;
          const treatment = getTreatment(
            testStep?.treatments || [],
            draftReturn.treatmentHash,
          );
          if (testStep && treatment) {
            return {
              testId: standardizeName(testStep.name),
              treatmentId: standardizeName(treatment?.name),
            };
          }
        }
      }
    }
  }
  return { testId: undefined, treatmentId: undefined };
}

export function standardizeName(name: string) {
  return name.trim().toLowerCase().replace(/\s+/g, "_");
}

export function getReturnOrders(
  draftReturn: DraftReturnZod,
): Record<string, DraftReturnOrder> {
  return draftReturn.pendingReturnItems.reduce<
    Record<string, DraftReturnOrder>
  >((acc: Record<string, DraftReturnOrder>, pendingItem) => {
    const combinedItem = getCombinedReturnItem(draftReturn, pendingItem.id);
    const order = draftReturn.orders.find(
      (order) => order.id === combinedItem.returnableItem.orderId,
    );
    if (order && !acc[order.id]) {
      acc[order.id] = order;
    }
    return acc;
  }, {});
}

export function getCountryCode(
  name: string | null | undefined,
): string | undefined {
  if (!name) {
    return undefined;
  }

  return countries.find(
    (country) =>
      country.name.toLowerCase() === name.toLowerCase() ||
      country.code.toLowerCase() === name.toLowerCase(),
  )?.code;
}

export function getProvinceCode(
  name: string | null | undefined,
): string | undefined {
  if (!name) {
    return undefined;
  }

  return usaProvinces.find(
    (province) =>
      province.name.toLowerCase() === name.toLowerCase() ||
      province.code.toLowerCase() === name.toLowerCase(),
  )?.code;
}

export function getCountryName(
  code: string | null | undefined,
): string | undefined {
  if (!code) {
    return undefined;
  }

  return countries.find(
    (country) =>
      country.name.toLowerCase() === code.toLowerCase() ||
      country.code.toLowerCase() === code.toLowerCase(),
  )?.name;
}

export function buildConditionParams({
  draftReturn,
  returnableItem,
  primaryProduct,
  order,
  returnMethod,
  compensationMethods,
  numPendingReturnItems,
  totalAdjustmentValue,
  totalPriceOverride,
}: {
  draftReturn: DraftReturnZod;
  returnableItem: ReturnableItem;
  primaryProduct: ReturnProduct;
  order: DraftReturnOrder | undefined;
  returnMethod?: ReturnOptionMethod["type"];
  compensationMethods?: ReturnOptionMethod["type"][];
  numPendingReturnItems?: number;
  compensationMethod?: CompensationMethod;
  totalAdjustmentValue?: number;
  totalPriceOverride?: number;
}): ConditionParams {
  return {
    coverage: order?.protected ? Coverage.COVERED : Coverage.NOT_COVERED,
    packageProtection: order?.packageProtected
      ? Coverage.COVERED
      : Coverage.NOT_COVERED,
    finalSaleReturns: isPrimaryProductCoveredByFinalSale(primaryProduct, order)
      ? Coverage.COVERED
      : Coverage.NOT_COVERED,
    orderDiscounts: order?.discounts || [], //TODO: Use real discounts once removed
    productDiscounts: returnableItem.products.flatMap(
      (product) => product.productDiscounts || [],
    ), //TODO: Use real discounts
    discountedAmount: returnableItem.products.reduce(
      (acc, product) => acc + (product.productDiscountedAmount ?? 0),
      0,
    ),
    now: Temporal.Now.instant(),
    fulfilledOn: primaryProduct.fulfilledOn.toTemporalInstant(),
    deliveredOn: primaryProduct.deliveredOn.toTemporalInstant(),
    orderedOn: order?.orderDate?.toTemporalInstant() ?? Temporal.Now.instant(),
    lastFulfillmentUpdateOn:
      primaryProduct.lastFulfillmentUpdateOn.toTemporalInstant(),
    customerTags: draftReturn.customer.tags,
    customerCountry:
      getCountryCode(draftReturn.customer.shippingAddress?.country) ?? "",
    price:
      totalPriceOverride ??
      returnableItem.products.reduce(
        (acc, product) => acc + Number(product.price),
        0,
      ),
    returnValue: returnableItem.products.reduce(
      (acc, product) => acc + Number(product.returnValue),
      0,
    ),
    loyaltyAmount: primaryProduct?.loyaltyAmount
      ? +primaryProduct.loyaltyAmount
      : 0,
    sku: [primaryProduct.sku],
    orderTags: order?.tags || [],
    productProperties: Array.from(
      returnableItem.products.reduce((acc, product) => {
        const properties = product.productProperties || {};
        Object.keys(properties).forEach((key) => acc.add(key));
        return acc;
      }, new Set<string>()),
    ),
    productTags: [
      ...returnableItem.products.reduce((acc, product) => {
        product.productTags.forEach((tag) => acc.add(tag));
        return acc;
      }, new Set<string>()),
    ],
    productType: primaryProduct.productType,
    productVariantMetafields: returnableItem.products.reduce(
      (acc, product) => {
        Object.entries(product.productVariantMetafields || {}).forEach(
          ([key, value]) => {
            acc[key] = value;
          },
        );
        return acc;
      },
      {} as Record<string, string>,
    ),
    collections: returnableItem.products.flatMap(
      (product) => product.collections,
    ),
    salesChannel: order?.salesChannel ?? "",
    exchangeCount:
      order?.lineItems?.find((lineItem) =>
        compareMaybeGids(lineItem.id, returnableItem.lineItemId),
      )?.exchangeCount ?? 0,
    numCustomerClaims: draftReturn.customer.numCustomerClaims,
    numCustomerReturns: draftReturn.customer.numCustomerReturns,
    numOrders: draftReturn.customer.numOrders,
    returnRate: draftReturn.customer.returnRate,
    method: order?.paymentMethods || [],
    numItemsBeingReturned: numPendingReturnItems || 1,
    adjustmentAmount: totalAdjustmentValue ?? 0,
    returnMethod,
    compensationMethods,
    provider: order?.platform || Provider.SHOPIFY,
    purchasedExtendedWarranty: !!primaryProduct.extendedWarranty,
    warrantyExtension: Temporal.Duration.from({
      days: primaryProduct.extendedWarranty?.daysExtended ?? 0,
    }),
    warrantyActivationDelay: Temporal.Duration.from({
      days: primaryProduct.extendedWarranty?.warrantyActivationDelay ?? 0,
    }),
  };
}

export const getExpandedVariantExchangeProduct = (
  returnableItem: ReturnableItem,
  productId: string | null | undefined,
): ReturnProduct | undefined => {
  if (returnableItem.products.length === 1) {
    return returnableItem.products[0];
  }
  return returnableItem.products.find(
    (product) => product.productId === productId,
  );
};

export const getExpandedReturnItemProducts = (
  combinedItem: CombinedReturnItem,
): ReturnProduct[] => {
  const products: (ReturnProduct | undefined)[] = [];
  if (
    combinedItem.pendingReturnItem?.expandedProductVariantExchanges &&
    (combinedItem.pendingReturnItem.expandedProductVariantExchanges || [])
      .length > 0
  ) {
    for (const variantExchange of combinedItem.pendingReturnItem
      .expandedProductVariantExchanges) {
      products.push(
        getExpandedVariantExchangeProduct(
          combinedItem.returnableItem,
          variantExchange.productId,
        ),
      );
    }
  } else {
    products.push(getPrimaryProduct(combinedItem.returnableItem));
  }
  return products.filter((product) => !!product);
};

export const getExpandedReturnItemVariantExchanges = (
  combinedItem: CombinedReturnItem,
): VariantExchangeItem[] => {
  if (
    combinedItem.pendingReturnItem?.expandedProductVariantExchanges &&
    (combinedItem.pendingReturnItem.expandedProductVariantExchanges || [])
      .length > 0
  ) {
    return combinedItem.pendingReturnItem.expandedProductVariantExchanges;
  } else if (combinedItem.pendingReturnItem?.variantExchangeItem) {
    return [combinedItem.pendingReturnItem.variantExchangeItem];
  } else {
    return [];
  }
};

export const getVariantExchangeItemsAndProducts = (
  draftReturn: DraftReturnZod,
): {
  variant: VariantExchangeItem;
  product: ReturnProduct | undefined;
  quantity: number;
}[] => {
  const variantExchangeItems: {
    variant: VariantExchangeItem;
    product: ReturnProduct | undefined;
    quantity: number;
  }[] = [];
  const compensationMethod = getCompensationMethod(draftReturn);
  if (!compensationMethod) {
    return variantExchangeItems;
  }

  for (const [id, item] of Object.entries(compensationMethod.itemValues)) {
    if (!item.isVariantExchange) {
      continue;
    }

    const combinedItem = getCombinedReturnItem(draftReturn, id);
    if (combinedItem.returnableItem?.isExpanded) {
      const expandedVariantExchanges =
        getExpandedReturnItemVariantExchanges(combinedItem);

      for (const variantExchange of expandedVariantExchanges) {
        variantExchangeItems.push({
          variant: variantExchange,
          product: getExpandedVariantExchangeProduct(
            combinedItem.returnableItem,
            variantExchange.productId,
          ),
          quantity: combinedItem.pendingReturnItem?.quantity ?? 1,
        });
      }
    } else {
      if (combinedItem.pendingReturnItem?.variantExchangeItem) {
        variantExchangeItems.push({
          variant: combinedItem.pendingReturnItem?.variantExchangeItem,
          product: getPrimaryProduct(combinedItem.returnableItem),
          quantity: combinedItem.pendingReturnItem?.quantity ?? 1,
        });
      }
    }
  }

  return variantExchangeItems;
};

export type ReturnProductItem = {
  product: ReturnProduct;
  pendingItem: PendingReturnItem;
};

export const getReturnShippingProducts = (
  draftReturn: DraftReturnZod,
): ReturnProductItem[] => {
  const products: ReturnProductItem[] = [];
  const compensationMethod = getCompensationMethod(draftReturn);
  if (!compensationMethod) {
    return products;
  }

  for (const id of Object.keys(compensationMethod.itemValues)) {
    if (!compensationMethod.shippingRequired[id]) {
      continue;
    }

    const combinedItem = getCombinedReturnItem(draftReturn, id);
    if (combinedItem.returnableItem?.isExpanded) {
      const expandedProducts = getExpandedReturnItemProducts(combinedItem);
      products.push(
        ...expandedProducts.map((product) => ({
          product,
          pendingItem: combinedItem.pendingReturnItem,
        })),
      );
    } else {
      for (const product of combinedItem.returnableItem.products) {
        products.push({ product, pendingItem: combinedItem.pendingReturnItem });
      }
    }
  }

  return products.filter((product) => !!product);
};

export const getReturnNonShippingProducts = (
  draftReturn: DraftReturnZod,
): ReturnProductItem[] => {
  const products: ReturnProductItem[] = [];
  const compensationMethod = getCompensationMethod(draftReturn);
  if (!compensationMethod) {
    return products;
  }

  for (const id of Object.keys(compensationMethod.itemValues)) {
    if (compensationMethod.shippingRequired[id]) {
      continue;
    }

    const combinedItem = getCombinedReturnItem(draftReturn, id);
    if (combinedItem.returnableItem?.isExpanded) {
      const expandedProducts = getExpandedReturnItemProducts(combinedItem);
      products.push(
        ...expandedProducts.map((product) => ({
          product,
          pendingItem: combinedItem.pendingReturnItem,
        })),
      );
    } else {
      for (const product of combinedItem.returnableItem.products) {
        products.push({ product, pendingItem: combinedItem.pendingReturnItem });
      }
    }
  }

  return products;
};

export const getExpandedProductVariantExchangeItem = (
  pendingReturnItem: PendingReturnItem,
  productId: string,
): VariantExchangeItem | undefined => {
  if (pendingReturnItem?.variantExchangeItem) {
    return pendingReturnItem.variantExchangeItem;
  }

  return pendingReturnItem.expandedProductVariantExchanges?.find(
    (exchange) => exchange.productId === productId,
  );
};

export const getRelevantOrders = (
  draftReturn: DraftReturnZod,
): DraftReturnOrder[] => {
  return draftReturn.pendingReturnItems
    .map((item) => {
      const combinedItem = getCombinedReturnItem(draftReturn, item.id);
      return draftReturn.orders.find(
        (order) => order.id === combinedItem.returnableItem.orderId,
      );
    })
    .filter((order) => !!order);
};

export const getRelevantDisplayDiscountCodes = (
  draftReturn: DraftReturnZod,
): string[] => {
  return Array.from(
    new Set(
      getRelevantOrders(draftReturn).flatMap((order) =>
        order.discounts.map((d) => (d.code || d.title).replace(/-RETURN/g, "")),
      ),
    ),
  ).filter((code) => code && code !== "Exchange Credit");
};

export const walkFlow = ({
  draftReturn,
  flow,
  order,
  product,
  returnableItem,
  start,
}: {
  draftReturn: DraftReturnZod;
  flow: ReturnFlow;
  order: DraftReturnOrder | undefined;
  product: ReturnProduct;
  returnableItem: ReturnableItem;
  start: number;
}): {
  path: readonly number[];
  step: Step;
  rejected: RejectionDetails | undefined;
} => {
  const path = [];
  let i = start;
  while (i < flow.steps.length) {
    path.push(i);
    const step = flow.steps[i];
    switch (step?.type) {
      case Step.MANUAL_REVIEW:
      case Step.FLAG:
        // Move on to the next step
        i = step.next!;
        break;
      case Step.CONDITION: {
        const value = Condition.evaluate(
          step.condition,
          buildConditionParams({
            draftReturn,
            returnableItem,
            primaryProduct: product,
            order,
          }),
        );
        i = value ? step.nextTrue! : step.nextFalse!;
        break;
      }
      case Step.AB_TEST: {
        const treatment = getTreatment(
          step.treatments,
          draftReturn.treatmentHash,
        );
        if (!treatment) {
          throw new Error("No treatment found");
        }
        i = treatment.next;
        break;
      }
      case Step.REJECT:
        return {
          path,
          step,
          rejected: {
            returnableItemId: returnableItem.id.toString(),
            rejectMessage: step.message,
            rejectReason: "",
          },
        };
      case Step.RETURN:
      case Step.SUBMIT_CLAIM:
      case Step.SUBMIT_REPAIR:
      case Step.SUBMIT_WARRANTY:
      case Step.SUBMIT_WARRANTY_REGISTRATION:
      case Step.EW_UPSELL:
      case Step.BLOCK:
      case Step.INPUT:
      case Step.INFORMATION:
      case Step.MULTIPLE_CHOICE:
        // End of the road, at least for now.
        return { path, step, rejected: undefined };
      default:
        throw new Error(
          `Invalid return flow step type: ${step?.type.description}`,
        );
    }
  }
  return { path, step: flow.steps[i]!, rejected: undefined };
};

export interface RejectionDetails {
  rejectReason: string;
  rejectMessage: string;
  returnableItemId: string;
}
