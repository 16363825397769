import { CurrencyCode } from "@redotech/shopify-client/storefront.graphql";
import { AdvancedFlowTokenType } from "../../type-system/data-types/base-types";
import { defineSchema, SchemaInstance } from "../../type-system/schema";

try {
  // eslint-disable-next-line
  require("temporal-polyfill/global"); // useful for storybook (since it uses webpack not bazel)
} catch (e) {
  // do nothing, this is only for storybook
}

const discountCodeSchema = defineSchema({
  fields: {
    discountCode: {
      dataType: "Maybe Text",
      documentation: "The discount code to share with the customer.",
    },
  },
});

export enum MarketingEntryPoint {
  SIGNUP_FORM = "form",
  SHOPIFY_SYNC = "shopify",
  SAVE_FOR_LATER = "save-for-later",
  LOGIN = "login",
  ORDER_TRACKING = "order-tracking",
  IMPORT = "import",
  UNKNOWN = "unknown",
  MERCHANT = "merchant",
  WARRANTY_REGISTRATION = "warranty-registration",
  META_LEAD_ADS = "meta-lead-ads",
}

export const marketingEntryPointPrettyNames: Record<
  MarketingEntryPoint,
  string
> = {
  [MarketingEntryPoint.SIGNUP_FORM]: "Signup form",
  [MarketingEntryPoint.SHOPIFY_SYNC]: "Shopify sync",
  [MarketingEntryPoint.SAVE_FOR_LATER]: '"Save for later" panel',
  [MarketingEntryPoint.LOGIN]: "Customer account login",
  [MarketingEntryPoint.ORDER_TRACKING]: "Order tracking",
  [MarketingEntryPoint.IMPORT]: "CSV Import",
  [MarketingEntryPoint.UNKNOWN]: "Unknown",
  [MarketingEntryPoint.MERCHANT]: "Manual subscribe",
  [MarketingEntryPoint.WARRANTY_REGISTRATION]: "Warranty registration",
  [MarketingEntryPoint.META_LEAD_ADS]: "Meta lead ads",
};

export const baseMarketingSchemaWithoutInteractiveCart = defineSchema({
  fields: {
    redoCustomerId: {
      dataType: "Metadata",
      documentation: "The Redo customer ID.",
    },
    customerFirstName: {
      dataType: "Maybe Text",
      documentation:
        "The first name of the customer who signed up for marketing messages.",
    },
    customerLastName: {
      dataType: "Maybe Text",
      documentation:
        "The last name of the customer who signed up for marketing messages.",
    },
    customerFullName: {
      dataType: "Maybe Text",
      documentation:
        "The full name of the customer who signed up for marketing messages.",
    },
  },
});

export const baseMarketingSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    cartContext: {
      dataType: "Cart Context",
      documentation:
        "Data that is useful for generating product recommendations for an interactive cart",
    },
  },
});

export const baseMarketingEmailSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    customerEmail: {
      dataType: "Email",
      documentation: "The email address of the customer.",
    },
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
    viewInBrowserLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to view the email in the browser. Only displays when the customer is subscribed",
    },
  },
});

export const baseMarketingBackInStockSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    productVariantId: {
      dataType: "Text",
      documentation: "The product variant ID that is out of stock.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation: "The email address of the customer.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation: "The phone number of the customer.",
    },
  },
});

export const baseMarketingLowInventorySchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    productVariantId: {
      dataType: "Text",
      documentation: "The product variant ID that is out of stock.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation: "The email address of the customer.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation: "The phone number of the customer.",
    },
  },
});

export const baseMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    alreadySubscribed: {
      dataType: "Boolean",
      documentation:
        "Whether the customer was already subscribed to marketing messages.",
    },
    entryPoint: {
      dataType: "Enum",
      values: Object.values(MarketingEntryPoint).filter(
        (value) =>
          // These entry points don't trigger flows
          ![MarketingEntryPoint.MERCHANT, MarketingEntryPoint.IMPORT].includes(
            value,
          ),
      ) as MarketingEntryPoint[],
      documentation: "Where the customer signed up for marketing messages.",
      prettyValues: marketingEntryPointPrettyNames,
    },
  },
});

export const customerGroupMembershipChangeSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned the cart.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation: "The phone number of the customer who abandoned the cart.",
    },
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
    segment: {
      dataType: "Token",
      tokenType: AdvancedFlowTokenType.CUSTOMER_GROUP_ID,
      documentation: "The segment that the customer entered or exited.",
    },
  },
});

export const emailMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    ...discountCodeSchema.fields,
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
    customerEmail: {
      dataType: "Email",
      documentation:
        "The email address of the customer who signed up for marketing emails.",
    },
  },
});

const baseAbandonmentSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
  },
});

export const baseMarketingCartAbandomentSchema = defineSchema({
  fields: {
    ...baseAbandonmentSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "The time the last item was added to the cart.",
    },
    isCartAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not begun the checkout process or checked out since updating their cart.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned the cart.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation: "The phone number of the customer who abandoned the cart.",
    },
    storeUrl: {
      dataType: "Maybe Url",
      documentation: "The URL of the store that the customer abandoned.",
    },
    checkoutUrl: {
      dataType: "Maybe Url",
      documentation: "The URL of the checkout that the customer abandoned.",
    },
  },
});

export const baseMarketingBrowseAbandomentSchema = defineSchema({
  fields: {
    ...baseAbandonmentSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "When the browsing last occurred.",
    },
    isBrowseAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not added anything to the cart or placed an order since browsing.",
    },
    customerEmail: {
      dataType: "Email",
      documentation:
        "The email address of the customer who abandoned their browsing session.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation:
        "The phone number of the customer who abandoned their browsing session.",
    },
    browseAbandonmentData: {
      dataType: "Browse Abandonment",
      documentation: "The product variants viewed by the customer.",
    },
  },
});

const dummyCartContext = (generatedByRedo: boolean) => ({
  existingCartId: generatedByRedo ? "preview" : null,
  productIdsForRecommendations: [],
  productIdsAlreadyInCart: [],
  alreadyInCart: [],
});

const exampleBaseMarketingSchemaInstance: SchemaInstance<
  typeof baseMarketingSchema
> = {
  redoCustomerId: "65f9d2d37fd7c5a1d2c19876",
  customerFirstName: undefined,
  customerLastName: undefined,
  customerFullName: undefined,
  cartContext: dummyCartContext(true),
};

export const baseMarketingCheckoutAbandonmentSchema = defineSchema({
  fields: {
    ...baseAbandonmentSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "When the last customer action on checkout occurred.",
    },
    isCheckoutAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not placed an order since starting checkout.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned their browsing session.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation:
        "The phone number of the customer who abandoned their browsing session.",
    },
    checkout: {
      dataType: "Shopify Checkout",
      documentation: "Data about the customer's checkout session",
    },
    storeUrl: {
      dataType: "Maybe Url",
      documentation: "The URL of the store that the customer abandoned.",
    },
    checkoutUrl: {
      dataType: "Maybe Url",
      documentation: "The URL of the checkout that the customer abandoned.",
    },
  },
});

const exampleAbandonmentSchemaInstance: SchemaInstance<
  typeof baseAbandonmentSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  isAbandonment: true,
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
};

export const exampleBaseMarketingCheckoutAbandonmentSchemaInstance: SchemaInstance<
  typeof baseMarketingCheckoutAbandonmentSchema
> = {
  ...exampleAbandonmentSchemaInstance,
  isAbandonment: true,
  time: Temporal.Now.instant(),
  isCheckoutAbandoned: true,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  checkout: {
    attributes: [],
    billingAddress: null,
    currencyCode: CurrencyCode.Usd,
    discountApplications: [],
    email: "john.doe@example.com",
    lineItems: [],
    order: null,
    shippingAddress: null,
    shippingLine: null,
    subtotalPrice: { amount: 100, currencyCode: CurrencyCode.Usd },
    phone: "+1234567890",
    token: "",
    totalPrice: { amount: 105, currencyCode: CurrencyCode.Usd },
    totalTax: { amount: 5, currencyCode: CurrencyCode.Usd },
    transactions: [],
  },
  cartContext: dummyCartContext(false),
  storeUrl: "https://example.com",
  checkoutUrl: "https://example.com/checkout",
};

export const exampleBaseMarketingBrowseAbandomentSchemaInstance: SchemaInstance<
  typeof baseMarketingBrowseAbandomentSchema
> = {
  ...exampleAbandonmentSchemaInstance,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  isBrowseAbandoned: true,
  isAbandonment: true,
  time: Temporal.Now.instant(),
  browseAbandonmentData: {
    viewedProducts: [
      {
        id: "123",
        image: null,
        price: { amount: 100, currencyCode: CurrencyCode.Usd },
        product: {
          id: "123",
          title: "T-Shirt",
          type: null,
          untranslatedTitle: "T-Shirt",
          url: "",
          vendor: "",
        },
        sku: "123",
        title: "Red T-Shirt",
        untranslatedTitle: "Red T-Shirt",
      },
    ],
  },
  cartContext: dummyCartContext(false),
};

export const exampleBaseMarketingCartAbandomentSchemaInstance: SchemaInstance<
  typeof baseMarketingCartAbandomentSchema
> = {
  ...exampleAbandonmentSchemaInstance,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  isCartAbandoned: true,
  isAbandonment: true,
  time: Temporal.Now.instant(),
  cartContext: dummyCartContext(false),
  storeUrl: "https://example.com",
  checkoutUrl: "https://example.com/checkout",
};

export const exampleBaseMarketingEmailSchemaInstance: SchemaInstance<
  typeof baseMarketingEmailSchema
> = {
  redoCustomerId: "65f9d2d37fd7c5a1d2c19876",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
  viewInBrowserLink: "https://example.com/view-in-browser",
  cartContext: dummyCartContext(true),
  customerFirstName: undefined,
  customerLastName: undefined,
  customerFullName: undefined,
};

export const exampleSegmentMembershipChangeSchemaInstance: SchemaInstance<
  typeof customerGroupMembershipChangeSchema
> = {
  redoCustomerId: "65f9d2d37fd7c5a1d2c19876",
  customerEmail: "john@fake.com",
  customerPhone: "+1234567890",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
  cartContext: dummyCartContext(true),
  segment: "65f9d2d37fd7c5a1d2c19876",
  customerFirstName: undefined,
  customerLastName: undefined,
  customerFullName: undefined,
};

export const baseMarketingSmsSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation: "The phone number of the customer.",
    },
    customer: { dataType: "Customer", documentation: "The customer object." },
  },
});

const exampleBaseMarketingSignupSchema: SchemaInstance<
  typeof baseMarketingSignupSchema
> = {
  redoCustomerId: "65f9d2d37fd7c5a1d2c19876",
  alreadySubscribed: false,
  entryPoint: MarketingEntryPoint.SIGNUP_FORM,
  cartContext: dummyCartContext(true),
  customerFirstName: undefined,
  customerLastName: undefined,
  customerFullName: undefined,
};

export const exampleEmailMarketingSignupSchema: SchemaInstance<
  typeof emailMarketingSignupSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  discountCode: "EMAILABCD",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
};

export const smsMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation:
        "The phone number of the customer who signed up for marketing SMS.",
    },
  },
});

export const exampleSmsMarketingSignupSchema: SchemaInstance<
  typeof smsMarketingSignupSchema
> = { ...exampleBaseMarketingSignupSchema, customerPhoneNumber: "+1234567890" };

export const smsMarketingConfirmedSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    ...discountCodeSchema.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation:
        "The phone number of the customer who confirmed their marketing SMS subscription.",
    },
  },
});

export const exampleSmsMarketingConfirmedSchema: SchemaInstance<
  typeof smsMarketingConfirmedSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  discountCode: "SMSABCD",
  customerPhoneNumber: "+1234567890",
};

export const exampleBaseMarketingBackInStockSchemaInstance: SchemaInstance<
  typeof baseMarketingBackInStockSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  productVariantId: "123",
  customerEmail: "john@fake.com",
  customerPhone: "+1234567890",
};

export const exampleBaseMarketingLowInventorySchemaInstance: SchemaInstance<
  typeof baseMarketingLowInventorySchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  productVariantId: "123",
  customerEmail: "john@fake.com",
  customerPhone: "+1234567890",
};

export const marketingWarrantyRegistrationSchema = defineSchema({
  fields: {
    ...baseMarketingEmailSchema.fields,
    ...discountCodeSchema.fields,
    shopNowLink: {
      dataType: "Maybe Url",
      documentation: "The link to the product on the store.",
    },
    product: {
      dataType: "Product",
      documentation:
        "The product that the customer is registering a warranty for.",
    },
    teamName: {
      dataType: "Text",
      documentation:
        "The name of the team that the customer is registering a warranty for.",
    },
    teamEmail: {
      dataType: "Email",
      documentation:
        "The email of the team that the customer is registering a warranty for.",
    },
  },
});

export const exampleMarketingWarrantyRegistrationSchemaInstance: SchemaInstance<
  typeof marketingWarrantyRegistrationSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  discountCode: "WARRANTYABCD",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
  viewInBrowserLink: "https://example.com/view-in-browser",
  shopNowLink: "https://example.com/shop-now",
  teamName: "Team Name",
  teamEmail: "team@fake.com",
  product: {
    id: 123,
    title: "T-Shirt",
    variantTitle: "Red T-Shirt",
    image: { src: "https://example.com/image.jpg" },
    price: 100,
    priceSet: { presentment_money: { amount: 100, currency_code: "USD" } },
    isRedo: false,
    requiresShipping: true,
    tags: ["tag1", "tag2"],
    properties: { prop1: "value1", prop2: "value2" },
    sku: "123",
    green_return: false,
    vendor: "vendor",
    handle: "handle",
    compareAtPrice: "100",
  },
};
