import { manualLabelInstructionsDefault } from "@redotech/redo-model/returns/default-texts";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
export const ManualLabelCard = memo(function ManualLabelCard({
  instructions,
}: {
  instructions: string[] | undefined;
}) {
  const instructionsToDisplay = instructions?.length
    ? instructions
    : manualLabelInstructionsDefault;
  return (
    <Flex flexDirection="column">
      <Text fontSize="lg" fontWeight="semibold">
        Next steps
      </Text>
      {instructionsToDisplay?.map((instruction, index) => (
        <Flex
          align="flex-start"
          flexDirection="column"
          gap="xl"
          justify="flex-start"
          key={index}
        >
          <Flex gap="xl">
            <Text>{`${index + 1}.`}</Text>
            <Text>{instruction}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
});
