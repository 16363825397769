import {
  getResource,
  Resource,
} from "@redotech/redo-model/localization/resource";
import { ReturnWarning } from "@redotech/redo-model/return";
import {
  PickupLocation,
  pickupLocationOptions,
} from "@redotech/redo-model/return-flow";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import { Trackable, TrackableType } from "@redotech/redo-model/trackable";
import { ReturnAppSettings } from "../../settings";
import { isInternationalReturn } from "../../util";

export enum StepTypeEnum {
  NONE = "none",
  EXCHANGE_ORDER = "exchange_order",
  MANUAL_REVIEW = "manual_review",
  GREEN_RETURN = "green_return",
  IN_TRANSIT = "in_transit",
  DELIVERED = "delivered",
  PROCESSED = "processed",
  WARNING = "warning",
}

export type StepType =
  | ({ type: "none"; stepType: StepTypeEnum.NONE } & Step)
  | ({ type: "manual-review"; stepType: StepTypeEnum.MANUAL_REVIEW } & Step)
  | ({ type: "exchange-order"; stepType: StepTypeEnum.EXCHANGE_ORDER } & Step)
  | ({
      type: "in-store-print-packing-slip";
      stepType: StepTypeEnum.IN_TRANSIT;
      packingSlipLink?: string;
    } & Step)
  | ({ type: "in-store-return"; stepType: StepTypeEnum.IN_TRANSIT } & Step)
  | ({ type: "in-store-processed"; stepType: StepTypeEnum.PROCESSED } & Step)
  | ({
      type: "happy-returns-drop-off";
      happyReturnsData: any;
      zipCode: string | undefined | null;
      stepType: StepTypeEnum.IN_TRANSIT;
      //todo
    } & Step)
  | ({
      type: "happy-returns-processed";
      stepType: StepTypeEnum.PROCESSED;
    } & Step)
  | ({
      type: "shipment-transit";
      shipmentInfo?: {
        carrier: string | undefined;
        commercialInvoice: string | undefined | null;
        trackable: Trackable & { type: TrackableType.RETURN };
      };
      stepType: StepTypeEnum.IN_TRANSIT;
    } & Step)
  | ({
      type: "pickup-print-label";
      shipmentInfo?: {
        carrier: string | undefined;
        commercialInvoice: string | undefined | null;
        trackable: Trackable & { type: TrackableType.RETURN };
      };
      stepType: StepTypeEnum.IN_TRANSIT;
    } & Step)
  | ({ type: "pickup-attach-label"; stepType: StepTypeEnum.IN_TRANSIT } & Step)
  | ({
      type: "pickup-place-package";
      //setPickupInfo: (data: PickupData) => void TODO: Allow rescheduling
      trackable: Trackable;
      stepType: StepTypeEnum.IN_TRANSIT;
    } & Step)
  | ({
      type: "drop-off-package";
      trackable: Trackable & { type: TrackableType.RETURN };
      stepType: StepTypeEnum.IN_TRANSIT;
    } & Step)
  | ({ type: "processed"; stepType: StepTypeEnum.PROCESSED } & Step)
  | ({ type: "processed-repair"; stepType: StepTypeEnum.PROCESSED } & Step)
  | ({
      type: "ship-back-repair";
      trackable: Trackable;
      stepType: StepTypeEnum.PROCESSED;
    } & Step)
  | ({
      type: "green-return-instructions";
      stepType: StepTypeEnum.GREEN_RETURN;
    } & Step)
  | ({
      type: "green-return-processed";
      stepType: StepTypeEnum.PROCESSED;
    } & Step)
  | ({ type: "warning"; stepType: StepTypeEnum.WARNING } & Step);

export type Step = {
  stepType: StepTypeEnum;
  title: string;
  subtitle: string;
  complete: boolean;
};
export interface UseStepsResponse {
  steps: StepType[] | undefined;
  stepResource: Resource | undefined;
}

const getConfirmationIds = (
  trackable: Trackable & { type: TrackableType.RETURN },
) => {
  const confirmationIds = trackable.shipments
    .filter((shipment) => shipment?.pickup?.confirmationNumber)
    .map(
      (shipment) =>
        `Pickup confirmation ID: ${shipment.pickup.confirmationNumber}`,
    )
    .join("\n");
  if (confirmationIds.length > 0) {
    return "\n\n" + confirmationIds;
  }
  return confirmationIds;
};

export function useSteps({
  settings,
  trackable,
}: {
  settings: ReturnAppSettings | undefined;
  trackable: Trackable | undefined;
}): UseStepsResponse {
  //Someone refactor this, I don't have time

  const steps: StepType[] = [];
  if (trackable?.type !== TrackableType.RETURN || !settings) {
    return { steps: undefined, stepResource: undefined };
  }
  const isRepair = trackable.returnProducts.some(
    (product: any) => product.strategy === "repair",
  );

  const isInternational = isInternationalReturn(
    trackable.originalOrder.shopify.shippingAddress?.country,
    trackable.originalOrder.shopify.billingAddress?.country,
    settings,
  );

  const isBuyYourOwnLabel =
    settings.returns?.buyYourOwnLabel &&
    (isInternational || settings.returns?.byolDomestically);

  const hasPickup =
    trackable.pickup && trackable.pickup.status !== "pending_refund";
  const showQRCodeMessage =
    trackable.type === TrackableType.RETURN && !!trackable.form_label;
  const carrier = trackable?.fulfillments[0]?.trackers[0]?.carrier;

  const copyResource = getResource({
    variant: hasPickup ? "pickup" : trackable.returnType,
    overrides: settings.resourceOverride,
    // This is an example of how we could get the language region based on the country code
    // language: getLanguageRegion({
    //   countryCode: returnData.shipping_address?.country_code,
    // }),
  });

  // Special Case for Mr. Beast
  if (settings.storeUrl === "mr-beast-refunds.myshopify.com") {
    steps.push(
      {
        type: "none",
        title: "We'll review your refund request",
        subtitle: " Please allow up to 30 days for processing and verification",
        complete: false,
        stepType: StepTypeEnum.NONE,
      },
      {
        type: "green-return-instructions",
        title: "Keep your items",
        subtitle: "You do not need to send your items back.",
        complete: false,
        stepType: StepTypeEnum.GREEN_RETURN,
      },
      {
        type: "green-return-processed",
        title: copyResource.GREEN_RETURN_PROCESS_STEP_TEXT,
        subtitle: "",
        complete: false,
        stepType: StepTypeEnum.PROCESSED,
      },
    );

    checkStepsCompletion(trackable, steps);
    return { steps, stepResource: copyResource };
  }

  if (trackable?.markedForManualReview && !settings.hideManualReviewStep) {
    steps.push({
      type: "manual-review",
      title: copyResource.STATUS_MANUAL_REVIEW_TITLE,
      subtitle: trackable.returnProducts.some(
        (product) => !product.green_return,
      )
        ? copyResource.STATUS_MANUAL_REVIEW_SUBTITLE
        : "",
      complete: false,
      stepType: StepTypeEnum.MANUAL_REVIEW,
    });
  }

  if (trackable.draftOrderURL) {
    let subtitle = "";
    if (settings.exchanges.createLabelOnDraftOrderComplete) {
      subtitle = "You'll receive your label after you complete your order";
    }
    steps.push({
      type: "exchange-order",
      title: "Pay for exchange order",
      subtitle,
      complete: false,
      stepType: StepTypeEnum.EXCHANGE_ORDER,
    });
  }
  // If there are no label warnings
  if (
    !trackable.warnings?.some(
      (warning: ReturnWarning) => warning.type === "label",
    )
  ) {
    if (trackable.inStoreReturn) {
      const hasPackingSlip =
        settings.inStoreDropOffPackingSlipEnabled && trackable.packingSlipLink;
      if (hasPackingSlip) {
        steps.push({
          type: "in-store-print-packing-slip",
          title: "Print off return authorization",
          subtitle: "Click the button to print the return authorization",
          complete: false,
          stepType: StepTypeEnum.IN_TRANSIT,
          packingSlipLink: trackable.packingSlipLink,
        });
      }
      steps.push({
        type: "in-store-return",
        title: "Return in-store",
        subtitle: "You can bring your items to the store",
        complete: false,
        stepType: StepTypeEnum.IN_TRANSIT,
      });
      steps.push({
        type: "in-store-processed",
        title: "We process the return",
        subtitle: "",
        complete: false,
        stepType: StepTypeEnum.PROCESSED,
      });
    } else if (trackable.happyReturnsData) {
      steps.push({
        type: "happy-returns-drop-off",
        title: "Drop off your items",
        subtitle: "You can drop off your items at any Happy Returns® location",
        happyReturnsData: trackable.happyReturnsData,
        zipCode: trackable.shipping_address?.zip,
        stepType: StepTypeEnum.IN_TRANSIT,
        complete: false,
      });
      steps.push({
        type: "happy-returns-processed",
        title: copyResource.STATUS_PROCESSED_TITLE,
        subtitle: copyResource.STATUS_PROCESSED_SUBTITLE,
        complete: false,
        stepType: StepTypeEnum.PROCESSED,
      });
    } else if (
      trackable.returnProducts.some((product) => !product.green_return)
    ) {
      const commercialInvoice = trackable.shipment?._shipment?.forms?.find(
        (form: any) => form.form_type === "commercial_invoice",
      )?.form_url;
      const singleShipment = !(trackable.shipments.length > 1);

      steps.push({
        type: "shipment-transit",
        title: copyResource.STATUS_IN_TRANSIT_TITLE,
        subtitle: singleShipment
          ? copyResource.STATUS_IN_TRANSIT_SUBTITLE +
            (showQRCodeMessage
              ? " " + copyResource.STATUS_IN_TRANSIT_QR_CODE
              : "")
          : copyResource.STATUS_IN_TRANSIT_MULTI_SHIPMENT_SUBTITLE,
        stepType: StepTypeEnum.IN_TRANSIT,
        shipmentInfo:
          !!trackable.shipment && singleShipment && !hasPickup
            ? {
                carrier: carrier,
                commercialInvoice: commercialInvoice,
                trackable: trackable,
              }
            : undefined,

        complete: false,
      });
      if (hasPickup) {
        steps.push({
          type: "pickup-print-label",
          title: "Print your shipping label",
          subtitle: "Click the button to print your shipping label",
          shipmentInfo:
            !!trackable.shipment && singleShipment
              ? {
                  carrier: carrier,
                  commercialInvoice: commercialInvoice,
                  trackable: trackable,
                }
              : undefined,

          complete: false,
          stepType: StepTypeEnum.IN_TRANSIT,
        });
        steps.push({
          type: "pickup-attach-label",
          title: "Attach shipping label",
          subtitle:
            "Place label on outside of the package and make sure barcode is visible",
          complete: false,
          stepType: StepTypeEnum.IN_TRANSIT,
        });
        const pickup = trackable.pickup;
        steps.push({
          type: "pickup-place-package",
          title: `Place the package at the ${
            pickupLocationOptions.get(
              pickup?.pickupLocation.packageLocation ??
                PickupLocation.FRONT_DOOR,
            )?.stepLabel || ""
          } on ${
            pickup?.pickupDate
              ? Temporal.PlainDate.from(pickup.pickupDate).toLocaleString()
              : "the date you schedule with support"
          }`,

          subtitle: `Your pickup address is:\n\n ${trackable.shipping_address?.name}\n ${trackable.shipping_address?.address1}\n ${trackable.shipping_address?.address2 !== "" ? trackable.shipping_address?.address2 + "\n" : ""}${trackable.shipping_address?.city}, ${trackable.shipping_address?.province} ${trackable.shipping_address?.zip}\n ${trackable.shipping_address?.country}${getConfirmationIds(trackable)}`,
          trackable: trackable,
          stepType: StepTypeEnum.IN_TRANSIT,
          complete: false,
        });
      } else {
        steps.push({
          type: "drop-off-package",
          title: `Drop off ${carrier ? "at " + carrier : "package"}`,
          subtitle: carrier
            ? `Take your package to a nearby ${carrier} location`
            : "",
          complete: false,
          trackable: trackable,
          stepType: StepTypeEnum.IN_TRANSIT,
        });
      }
      steps.push({
        type: "processed",
        title: copyResource.STATUS_PROCESSED_TITLE,
        subtitle: copyResource.STATUS_PROCESSED_SUBTITLE,
        complete: false,
        stepType: StepTypeEnum.PROCESSED,
      });
      if (isRepair) {
        steps.push({
          type: "processed-repair",
          title: "We process the repair",
          subtitle:
            "If the item(s) is not repairable, we will replace or refund the item",
          complete: false,
          stepType: StepTypeEnum.PROCESSED,
        });
        steps.push({
          type: "ship-back-repair",
          title: "We ship the repaired item",
          subtitle: "",
          complete: false,
          trackable: trackable,
          stepType: StepTypeEnum.PROCESSED,
        });
      }
    } else {
      steps.push(
        {
          type: "green-return-instructions",
          title:
            settings.theme.green_return_confirmation_text ||
            (isBuyYourOwnLabel
              ? "Please visit a local carrier to purchase a label and get your items shipped"
              : "Keep your items"),
          subtitle:
            settings.theme.green_return_confirmation_description ||
            (isBuyYourOwnLabel
              ? `Our address is: \n${trackable.merchant_address?.name}\n${trackable.merchant_address?.address1} ${trackable.merchant_address?.address2}\n${trackable.merchant_address?.city}, ${trackable.merchant_address?.province} ${trackable.merchant_address?.zip}\n${trackable.merchant_address?.country}`
              : "You do not need to send your items back."),
          complete: false,
          stepType: StepTypeEnum.GREEN_RETURN,
        },
        {
          type: "green-return-processed",
          title: copyResource.GREEN_RETURN_PROCESS_STEP_TEXT,
          subtitle: "",
          complete: false,
          stepType: StepTypeEnum.PROCESSED,
        },
      );
    }
  } else {
    // If there are label warnings, we need to let them know that we are working on it
    steps.push({
      type: "warning",
      title: "We're working on it",
      subtitle: "We will resolve any issues and email you with our solution",
      complete: false,
      stepType: StepTypeEnum.WARNING,
    });
  }

  checkStepsCompletion(trackable, steps);
  return { steps, stepResource: copyResource };
}

const checkStepsCompletion = (
  trackable: Trackable & { type: TrackableType.RETURN },
  steps: Step[],
) => {
  // Go through steps array backwards. If the step is complete,
  // mark the rest of the steps as complete.
  let isComplete = false;
  for (let i = steps.length - 1; i >= 0; i--) {
    const step = steps[i];
    if (!step) {
      continue;
    }
    if (isComplete) {
      step.complete = true;
      continue;
    }
    switch (step.stepType) {
      case StepTypeEnum.PROCESSED:
        step.complete = trackable.status === ReturnStatus.COMPLETE;
        break;
      case StepTypeEnum.DELIVERED:
        step.complete = [
          ReturnStatus.COMPLETE,
          ReturnStatus.DELIVERED,
        ].includes(trackable.status);
        break;
      case StepTypeEnum.IN_TRANSIT:
        step.complete = [
          ReturnStatus.COMPLETE,
          ReturnStatus.DELIVERED,
          ReturnStatus.IN_TRANSIT,
        ].includes(trackable.status);
        break;
      case StepTypeEnum.EXCHANGE_ORDER:
        step.complete = trackable.exchangeOrder.length > 0;
        break;
      case StepTypeEnum.MANUAL_REVIEW:
      case StepTypeEnum.GREEN_RETURN:
        step.complete = trackable.status !== ReturnStatus.NEEDS_REVIEW;
        break;
      case StepTypeEnum.NONE:
        continue;
    }
    isComplete = step.complete;
  }
};
