import { assertNever } from "@redotech/util/type";
import { compareMaybeGids, MaybeGid } from "./shopify/gid-utils";

export enum OriginExchangeProductType {
  VARIANT = "variant",
  ADVANCED = "advanced",
}

type BaseOriginExchangeProduct = {
  type: OriginExchangeProductType;
  newProductId: string;
  newVariantId: string;
};

type OriginVariantExchangeProduct = BaseOriginExchangeProduct & {
  type: OriginExchangeProductType.VARIANT;
  lineItemId: string;
  previousVariantId: string;
  isUnbundled: boolean;
  price: string;
  tax: string;
};

type OriginAdvancedExchangeProduct = BaseOriginExchangeProduct & {
  type: OriginExchangeProductType.ADVANCED;
  discountPrice: string;
  price: string;
  tax: string;
};

export type OriginExchangeProduct =
  | OriginVariantExchangeProduct
  | OriginAdvancedExchangeProduct;

export type OriginReturn = {
  exchangeProducts: OriginExchangeProduct[];
  // Total price adjustment + merchant adjustment across all products
  totalAdjustments: number;
  originOrder: OriginOrder;
};

type OriginLineItem = {
  id: string;
  variantId: string;
  discountPrice: string;
  price: string;
  tax: string;
};

// Recursive type (e.g. originOrder.originReturn.originOrder.originReturn...)
// When the origin order doesn't have an originReturn, it's the original order
export type OriginOrder = {
  isExchangeOrder: boolean;
  originReturn?: OriginReturn;
  lineItems: OriginLineItem[];
};

export type MaybeOriginOrder = OriginOrder | { error: string };
// Force at least one element in the array so people can't pass in an empty array
export type MaybeOriginOrderArray = [MaybeOriginOrder, ...MaybeOriginOrder[]];

const getReturnExchangeProduct = (
  originReturn: OriginReturn,
  originOrder: OriginOrder,
  variantId: MaybeGid,
  lineItemId: MaybeGid,
) => {
  const variantIndex = getIndex(lineItemId, variantId, originOrder);
  const matchedExchangeProducts = originReturn.exchangeProducts.filter(
    (exchangeProduct) =>
      compareMaybeGids(exchangeProduct.newVariantId, variantId),
  );
  if (matchedExchangeProducts.length > 0) {
    if (!matchedExchangeProducts[variantIndex]) {
      return matchedExchangeProducts[0];
    }
    return matchedExchangeProducts[variantIndex];
  }

  return null;
};

function getVariantExchangeItemTotals(
  originReturn: OriginReturn,
  returnExchangeProduct: OriginVariantExchangeProduct,
) {
  if (returnExchangeProduct.isUnbundled) {
    return {
      price: returnExchangeProduct.price,
      discountPrice: returnExchangeProduct.price,
      tax: returnExchangeProduct.tax,
    };
  }
  return getOriginProductTotals(
    returnExchangeProduct.lineItemId,
    returnExchangeProduct.previousVariantId,
    originReturn.originOrder,
  );
}

function getAdvancedExchangeItemTotals(
  originReturn: OriginReturn,
  returnExchangeProduct: OriginAdvancedExchangeProduct,
): { price: string; discountPrice: string; tax: string } {
  // If there is a net positive price adjustment, we need to remove that from the price of the
  // new items because we can't refund the customer more than they originally paid.
  // e.g. If someone returns a $100 item (with a $15 bonus) for a $115 item, then requests a refund, we can only refund $100
  const advancedExchangeProducts = originReturn.exchangeProducts.filter(
    (product) => product.type === OriginExchangeProductType.ADVANCED,
  );
  const totalAdvancedExchangeDiscountPrice = advancedExchangeProducts.reduce(
    (acc, product) => {
      return acc + parseFloat(product.discountPrice);
    },
    0,
  );

  let adjustment = 0;
  // Don't remove the adjustment if it's a fee, only if it's a bonus
  const isBonus = originReturn.totalAdjustments > 0;
  if (
    isBonus &&
    totalAdvancedExchangeDiscountPrice > 0 /* prevent division by 0 */
  ) {
    // Distribute the total adjustment to each of the new items
    const percentOfAdvancedExchangeItems =
      parseFloat(returnExchangeProduct.discountPrice) /
      totalAdvancedExchangeDiscountPrice;
    adjustment = originReturn.totalAdjustments * percentOfAdvancedExchangeItems;
  }

  return {
    price: (parseFloat(returnExchangeProduct.price) - adjustment).toString(),
    discountPrice: (
      parseFloat(returnExchangeProduct.discountPrice) - adjustment
    ).toString(),
    tax: returnExchangeProduct.tax,
  };
}

function getIndex(
  lineItemId: MaybeGid,
  variantId: MaybeGid,
  originOrder: OriginOrder,
) {
  // If you do a variant exchange and advanced exchange for the same variant,
  // it's possible they turn out to be different prices (since we ignore price
  // difference in variant exchange). This insures each one has the correct price
  const lineItemsWithMatchedVariantId = originOrder.lineItems.filter(
    (orderLineItem) => compareMaybeGids(orderLineItem.variantId, variantId),
  );
  const lineItemIndex = lineItemsWithMatchedVariantId.findIndex(
    (orderLineItem) => compareMaybeGids(orderLineItem.id, lineItemId),
  );

  return lineItemIndex;
}

export function getOriginProductTotals(
  lineItemId: MaybeGid,
  variantId: MaybeGid,
  originOrder: OriginOrder,
): { discountPrice: string; price: string; tax: string } | { error: string } {
  if (!originOrder.isExchangeOrder) {
    const lineItem = originOrder.lineItems.find((lineItem) =>
      compareMaybeGids(lineItem.id, lineItemId),
    );
    if (!lineItem) {
      return { error: `Line item ${lineItemId} not found in origin order` };
    }
    return {
      discountPrice: lineItem.discountPrice,
      price: lineItem.price,
      tax: lineItem.tax,
    };
  }

  const originReturn = originOrder.originReturn;
  if (!originReturn) {
    return { error: `Origin return not found for order` };
  }
  const returnExchangeProduct = getReturnExchangeProduct(
    originReturn,
    originOrder,
    variantId,
    lineItemId,
  );
  if (!returnExchangeProduct) {
    return {
      error: `Exchange product not found for line item ${lineItemId} and variant ${variantId}`,
    };
  }
  // If variant exchange, we'll get the origin price from the earliest possible order (recursively)
  // If advanced exchange, we'll get the origin price from the return that created this exchange order
  switch (returnExchangeProduct.type) {
    case OriginExchangeProductType.VARIANT:
      return getVariantExchangeItemTotals(originReturn, returnExchangeProduct);
    case OriginExchangeProductType.ADVANCED:
      return getAdvancedExchangeItemTotals(originReturn, returnExchangeProduct);
    default:
      assertNever(returnExchangeProduct);
  }
}
