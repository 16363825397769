import { defineSchema, SchemaInstance } from "../../type-system/schema";

/**
 * Fake because we don't save checkout tree in the AdvancedFlow collection,
 * but necessary for frontend type system.
 */
export const baseCheckoutTreeSchema = defineSchema({ fields: {} });

export const examplebaseCheckoutTreeInstance: SchemaInstance<
  typeof baseCheckoutTreeSchema
> = {};

export function createbaseCheckoutTreeInstance(): SchemaInstance<
  typeof baseCheckoutTreeSchema
> {
  return {};
}
