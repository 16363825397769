import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { Flex, FlexProps } from "@redotech/redo-web/flex";
import { WidthValue } from "@redotech/redo-web/theme/box";
import { NavigationHeader } from "./navigation-header";

const borderProps: FlexProps = {
  borderColor: "primary",
  borderStyle: "solid",
  borderWidth: "1px",
  radius: "md",
};
export function Card({
  children,
  maxWidth,
  previousClicked,
  headerRight,
  fullscreen = false,
  showBorder = false,
}: {
  maxWidth?: WidthValue;
  children: React.ReactNode;
  previousClicked?: () => void; //If undefined, it will remove the button
  headerRight?: React.ReactNode;
  fullscreen?: boolean;
  showBorder?: boolean;
}) {
  const screen = useScreenType();
  return (
    <Flex
      bgColor={fullscreen ? "primary" : undefined}
      grow={1}
      justify="center"
      pb={screen === ScreenType.DESKTOP ? "3xl" : "none"}
      pl={screen === ScreenType.DESKTOP ? "xl" : "none"}
      pr={screen === ScreenType.DESKTOP ? "xl" : "none"}
      pt={screen === ScreenType.DESKTOP ? "5xl" : "none"}
    >
      <Flex
        bgColor="primary"
        dir="column"
        gap="none"
        grow={1}
        maxW={maxWidth ?? "2xl"}
        p={screen === ScreenType.DESKTOP ? "5xl" : "xl"}
        radius={screen === ScreenType.DESKTOP ? "lg" : "none"}
        {...(showBorder ? borderProps : {})}
      >
        {previousClicked && (
          <NavigationHeader
            headerRight={headerRight}
            previousClicked={previousClicked}
          />
        )}

        {children}
      </Flex>
    </Flex>
  );
}
