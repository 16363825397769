import * as classNames from "classnames";
import { memo } from "react";
import * as loadingRedoAnimationCss from "./loading-redo-animation.module.css";
import { Spinner, SpinnerStyle } from "./spinner";

export const LoadingRedoAnimation = memo(function LoadingRedoAnimation({
  style,
  className,
}: {
  style?: SpinnerStyle;
  className?: string;
}) {
  return (
    <div
      className={classNames(loadingRedoAnimationCss.loadingSpinner, className)}
    >
      <div className={loadingRedoAnimationCss.spinnerContainer}>
        <Spinner style={style} />
      </div>
    </div>
  );
});
