import { CountryCode } from "@redotech/locale/countries";
import { ReturnProduct } from "./draft-return/draft-return-items";
import { ReturnFlow, Step } from "./return-flow";
import { Condition, RegistrationParameters } from "./return-flow/condition";
import { LineItem } from "./shopify-order";
import { PurchaseChannel } from "./warranties";
import {
  ProductVariantResponse as ProductVariantResponseInput,
  SubmitWarrantyRegistrationInput,
} from "./warranties/submit-warranty-registration-schema";

export type UserInfo = {
  email: string;
  firstName?: string;
  lastName?: string;
  emailOptIn?: boolean;
};

export type UserAddress = {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  country?: CountryCode;
  zip?: string;
};

export type ProductVariantResponse = {
  type: "images" | "text_area" | "videos" | "multiple_choice";
  value: string | string[] | URL[];
  message: string;
};

export type OnlineReferenceId = `${string}_${number}`;

export type EligibleLineItem = LineItem & { referenceId: OnlineReferenceId };

export type OnlineSourceDetails = {
  purchaseChannel: PurchaseChannel.ONLINE;
  orderId: string;
  eligibleLineItems?: EligibleLineItem[];
  referenceId?: OnlineReferenceId;
};

export type RetailSourceDetails = { purchaseChannel: PurchaseChannel.RETAIL };

// TODO: Reconcile with RegistrationSource
export type SourceDetails = OnlineSourceDetails | RetailSourceDetails;

export type DraftRegistration = {
  products: ReturnProduct[];
  source: SourceDetails;
  flow: ReturnFlow;
  userInfo?: UserInfo;
  userAddress?: UserAddress;
  productVariantResponses?: Record<string, ProductVariantResponse[]>;
};

export function addProduct(
  draftRegistration: DraftRegistration,
  product: ReturnProduct,
): DraftRegistration {
  const existingProduct = draftRegistration.products.find(
    (p) => p.id === product.id,
  );
  const sameVariant = existingProduct?.variantId === product.variantId;
  if (existingProduct && sameVariant) {
    return draftRegistration;
  }
  if (existingProduct && !sameVariant) {
    const newProducts = draftRegistration.products.map((p) =>
      p.id === product.id ? product : p,
    );
    return { ...draftRegistration, products: newProducts };
  }
  return {
    ...draftRegistration,
    products: [...draftRegistration.products, product],
  };
}

export function generateOnlineReferenceId(
  lineItem: LineItem,
  index: number,
): OnlineReferenceId {
  return `${lineItem.id}_${index}`;
}

export function parseOnlineReferenceId(referenceId: OnlineReferenceId): {
  lineItemId: string;
  index: number;
} {
  const [lineItemId, index] = referenceId.split("_");
  return { lineItemId, index: parseInt(index) };
}

export function addSourceDetails(
  draftRegistration: DraftRegistration,
  sourceDetails: SourceDetails,
): DraftRegistration {
  return { ...draftRegistration, source: sourceDetails };
}

export function addEligibleLineItem(
  draftRegistration: DraftRegistration,
  eligibleLineItem: EligibleLineItem,
): DraftRegistration {
  let newDraftRegistration = clearProducts(draftRegistration);
  newDraftRegistration = addProduct(
    newDraftRegistration,
    shopifyLineItemToReturnProduct(eligibleLineItem),
  );
  const sourceDetails = draftRegistration.source;
  if (sourceDetails.purchaseChannel === PurchaseChannel.ONLINE) {
    sourceDetails.referenceId = eligibleLineItem.referenceId;
  }
  newDraftRegistration = addSourceDetails(newDraftRegistration, sourceDetails);
  return newDraftRegistration;
}

// This is a terrible mapping, we should convert the line item to a return product on the backend
// or change the registration product to be more generic.
export function shopifyLineItemToReturnProduct(
  lineItem: LineItem,
): ReturnProduct {
  return {
    id: lineItem.product_id?.toString() ?? "",
    title: lineItem.title,
    variantTitle: lineItem.variant_title ?? "",
    variantId: lineItem.variant_id?.toString() ?? "",
    image: lineItem.image?.src ?? "",
    price: lineItem.price,
    tax: lineItem.tax_lines.map((taxLine) => taxLine.title).join(", "),
    productTags: lineItem.properties.map((property) => property.name ?? ""),
    productType: "",
    productVariantMetafields: {},
    collections: [],
    sku: lineItem.sku ?? "",
    quantity: lineItem.quantity,
    returnValue: lineItem.price,
    currency: lineItem.price_set?.presentment_money?.currency_code ?? "",
    deliveredOn: new Date(),
    fulfilledOn: new Date(),
    grams: lineItem.grams,
    lastFulfillmentUpdateOn: new Date(),
    shipmentLocationId: "",
    productId: lineItem.product_id?.toString() ?? "",
    productDiscounts: [],
    productDiscountedAmount: 0,
    loyaltyAmount: 0,
    productProperties: {},
    extendedWarranty: undefined,
    variantData: { productTitle: "", tags: [], options: [], variants: [] },
    fulfillmentOrigin: undefined,
    isUnbundled: false,
    isExpandedPrimaryProduct: false,
    originalPrice: undefined,
    taxRate: 0,
  };
}

export function clearProducts(
  draftRegistration: DraftRegistration,
): DraftRegistration {
  return { ...draftRegistration, products: [] };
}

export function addUserInfo(
  draftRegistration: DraftRegistration,
  userInfo: UserInfo,
): DraftRegistration {
  return { ...draftRegistration, userInfo };
}

export function addUserAddress(
  draftRegistration: DraftRegistration,
  userAddress: UserAddress,
): DraftRegistration {
  return { ...draftRegistration, userAddress };
}

export function addProductVariantResponses(
  draftRegistration: DraftRegistration,
  variantId: string,
  responses: ProductVariantResponse[],
): DraftRegistration {
  return {
    ...draftRegistration,
    productVariantResponses: {
      ...draftRegistration.productVariantResponses,
      [variantId]: responses,
    },
  };
}

export function getPrimaryProduct(
  draftRegistration: DraftRegistration | undefined,
): ReturnProduct | undefined {
  return draftRegistration?.products[0];
}

export function getProductVariantResponses(
  draftRegistration: DraftRegistration | undefined,
  variantId: string | undefined,
): ProductVariantResponse[] {
  if (!variantId || !draftRegistration?.productVariantResponses) {
    return [];
  }
  return draftRegistration.productVariantResponses[variantId];
}

function cleanProductVariantResponses(
  responses: ProductVariantResponse[],
): ProductVariantResponseInput[] {
  return responses.map((response) => ({
    ...response,
    value: Array.isArray(response.value)
      ? response.value.map((url) => url.toString())
      : response.value,
  }));
}

export function draftRegistrationToSubmitWarrantyRegistrationInput(
  draftRegistration: DraftRegistration | undefined,
): SubmitWarrantyRegistrationInput {
  const product = getPrimaryProduct(draftRegistration);
  if (!product) {
    throw new Error("No product found in draft registration");
  }
  const userInfo = draftRegistration?.userInfo;
  if (
    !userInfo ||
    !userInfo.firstName ||
    !userInfo.lastName ||
    !userInfo.email
  ) {
    throw new Error("No user info found in draft registration");
  }
  const userAddress = draftRegistration?.userAddress;
  if (
    !userAddress ||
    !userAddress.city ||
    !userAddress.country ||
    !userAddress.state ||
    !userAddress.street1 ||
    !userAddress.zip
  ) {
    throw new Error("No user address found in draft registration");
  }
  return {
    notifyCustomer: true,
    product: product,
    responses: cleanProductVariantResponses(
      getProductVariantResponses(draftRegistration, product.id),
    ),
    account: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      address: {
        city: userAddress.city,
        country: userAddress.country,
        state: userAddress.state,
        street1: userAddress.street1,
        street2: userAddress.street2 ?? "",
        zip: userAddress.zip,
      },
    },
    optInMarketing: userInfo.emailOptIn ?? false,
    source: draftRegistration?.source,
  };
}
export function walkFlow({
  draftRegistration,
  flow,
  start,
}: {
  draftRegistration: DraftRegistration | undefined;
  flow: ReturnFlow;
  start: number;
}): { path: number[] } {
  const path = [];
  let i = start;
  while (i < flow.steps.length) {
    path.push(i);
    const step = flow.steps[i];
    switch (step?.type) {
      case Step.CONDITION: {
        const value = Condition.evaluateRegistration(
          step.condition,
          buildConditionParams(
            draftRegistration,
            getPrimaryProduct(draftRegistration),
          ),
        );
        i = value ? step.nextTrue! : step.nextFalse!;
        break;
      }
      case Step.INPUT:
      case Step.MULTIPLE_CHOICE:
      case Step.INFORMATION:
      case Step.SUBMIT_WARRANTY_REGISTRATION:
        return { path };
      default:
        throw new Error(
          `Invalid registration flow step type: ${step?.type.description}`,
        );
    }
  }
  return { path };
}

function buildConditionParams(
  draftRegistration: DraftRegistration | undefined,
  product: ReturnProduct | undefined,
): RegistrationParameters {
  return {
    customerCountry: draftRegistration?.userAddress?.country ?? "Unknown",
    customerTags: [],
    productProperties: Object.keys(product?.productProperties ?? {}),
    productTags: product?.productTags ?? [],
    productType: product?.productType ?? "Unknown",
    sku: product?.sku ? [product.sku] : [],
    purchaseChannel:
      draftRegistration?.source?.purchaseChannel ?? PurchaseChannel.RETAIL,
  };
}
